import { FC } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import addressMapButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/address_map_button_click';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';
import Text from 'bloko/blocks/text';

import paths from 'src/app/routePaths';
import { VacancyResponsePopupResponse } from 'src/components/VacancyResponseLink';
import ContactsCallTracking from 'src/components/VacancyView/VacancyContactsCallTracking';
import VacancyContactsMagritte from 'src/components/VacancyView/VacancyContactsMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { VacancyAddress, ContactInfo } from 'src/models/vacancyView.types';

type VacancySearchItemContactsProps = {
    callTrackingEnabled?: boolean;
    contactInfo?: ContactInfo;
    onClose: () => void;
    vacancyId: number;
    address?: VacancyAddress;
    chatButtonInfo: VacancyResponsePopupResponse | null;
    employerId: number;
    chatWritePossibility: ChatWritePossibility;
    isMagritteExp?: boolean;
};

const VacancySearchItemContacts: FC<VacancySearchItemContactsProps> = ({
    contactInfo,
    vacancyId,
    address,
    callTrackingEnabled,
    chatButtonInfo,
    employerId,
    onClose,
    chatWritePossibility,
    isMagritteExp,
}) => {
    const isZarplata = useIsZarplataPlatform();

    const onClickPhone = () => {
        Analytics.sendHHEvent('applicantCallIntent', {
            from: 'serp',
            vacancy_id: vacancyId, // eslint-disable-line camelcase
        });
        Analytics.sendEvent('applicant', 'call-from-vacancy');
        if (isZarplata) {
            Analytics.addEventPixels('ZP_CALL');
        }
    };

    const onClickEmail = () => {
        Analytics.sendHHEvent('applicantEmailIntent', {
            from: 'serp',
            vacancy_id: vacancyId, // eslint-disable-line camelcase
        });
    };

    const onClickAddress = () => {
        addressMapButtonClick({ hhtmSourceLabel: 'vacancy_serp_contacts_drop', vacancyId });
    };

    const { fio, email } = contactInfo || {};
    const phones = contactInfo?.phones?.phones;

    if (isMagritteExp) {
        return (
            <VacancyContactsMagritte
                vacancyId={vacancyId}
                contactInfo={contactInfo}
                onPhoneClick={onClickPhone}
                onEmailClick={onClickEmail}
                close={onClose}
                isCallTrackingEnabled={!!callTrackingEnabled}
                chatButtonInfo={chatButtonInfo}
                employerId={employerId}
                isChatEnabled={chatWritePossibility !== ChatWritePossibility.Disabled}
            />
        );
    }

    return (
        <div
            className={classnames('vacancy-contacts', 'vacancy-contacts_opened', {
                'vacancy-contacts_search': !callTrackingEnabled,
            })}
        >
            {callTrackingEnabled ? (
                <ContactsCallTracking
                    contactInfo={contactInfo}
                    vacancyId={vacancyId}
                    address={address?.displayName}
                    onPhoneClick={onClickPhone}
                    onEmailClick={onClickEmail}
                    onAddressClick={onClickAddress}
                    onClose={onClose}
                    chatButtonInfo={chatButtonInfo}
                    employerId={employerId}
                    chatWritePossibility={chatWritePossibility}
                />
            ) : (
                <>
                    <Text data-qa="vacancy-serp__vacancy_contacts-fio">{fio}</Text>
                    {phones?.map((phone, index) => {
                        const { country, city, number, comment } = phone;
                        return (
                            <div key={index}>
                                <div
                                    className="vacancy-contacts__phone vacancy-contacts__phone_search"
                                    data-qa="vacancy-serp__vacancy_contacts-phone"
                                >
                                    <span className="vacancy-contacts__phone-text">
                                        +{country}&#160;{city}&#160;{number}
                                    </span>
                                    <a
                                        href={`tel:+${country}${city}${number}`}
                                        className="vacancy-contacts__phone-link"
                                        data-qa="vacancy-serp__vacancy_contacts-phone-link"
                                        onClick={onClickPhone}
                                    >
                                        +{country}&#160;{city}&#160;{number}
                                    </a>
                                </div>
                                <div>{comment}</div>
                            </div>
                        );
                    })}
                    <div>
                        <a
                            href={`mailto:${email ?? ''}`}
                            data-qa="vacancy-serp__vacancy_contacts-email"
                            onClick={onClickEmail}
                        >
                            {email}
                        </a>
                    </div>
                    {address && (
                        <div className="vacancy-contacts__address" data-qa="vacancy-serp__vacancy_contacts-address">
                            <a href={`${paths.vacancySearchMap}?vacancy_id=${vacancyId}`} onClick={onClickAddress}>
                                {address.displayName}
                            </a>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default VacancySearchItemContacts;
