import { FC } from 'react';

import { Text, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import TextBloko from 'bloko/blocks/text';

import OrganizationForm from 'src/components/Employer/OrganizationForm';
import useGetCompanyUrl from 'src/hooks/companies/useGetCompanyUrl';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyCompany } from 'src/models/vacancySearch/vacancySearchItem.types';

import styles from './styles.less';

const Features = {
    hideInterfaceForModerators: 'hide_interface_for_moderators',
};

type CompanyNameProps = {
    company: VacancyCompany;
    advClickUrl?: string;
    isEmployerLinkDisabled?: boolean;
};

const CompanyName: FC<CompanyNameProps> = ({ company, advClickUrl, isEmployerLinkDisabled }) => {
    const isMagritte = useMagritte();
    const userId = useSelector(({ userId }) => userId);
    const hideInterfaceForModerators = useSelector(
        ({ features }) => features[Features.hideInterfaceForModerators]
    ) as string;
    const companyUrl = useGetCompanyUrl(company, advClickUrl);
    const shouldHideInterfaceForModerators = hideInterfaceForModerators?.split(',')?.includes(userId);
    const companyNameText = (
        <>
            <OrganizationForm organizationFormId={company.employerOrganizationFormId} />
            {company.visibleName}
        </>
    );

    if (isMagritte) {
        if (!company.id) {
            return (
                <Text typography="label-3-regular" Element="span">
                    {company.visibleName}
                </Text>
            );
        }

        if (shouldHideInterfaceForModerators) {
            return (
                <Text typography="label-3-regular" Element="span">
                    {companyNameText}
                </Text>
            );
        }

        if (isEmployerLinkDisabled) {
            <Text typography="label-3-regular" data-qa="vacancy-serp__vacancy-employer" Element="span">
                {companyNameText}
            </Text>;
        }
        return (
            <MagritteLink
                typography="label-3-regular"
                Element={Link}
                to={companyUrl}
                style="neutral"
                data-qa="vacancy-serp__vacancy-employer"
                inline
            >
                {companyNameText}
            </MagritteLink>
        );
    }

    if (!company.id) {
        return <>{company.visibleName}</>;
    }

    if (shouldHideInterfaceForModerators) {
        return <>{companyNameText}</>;
    }

    return isEmployerLinkDisabled ? (
        <TextBloko data-qa="vacancy-serp__vacancy-employer" Element="span">
            <span className={styles.companyInfoText}>{companyNameText}</span>
        </TextBloko>
    ) : (
        <BlokoLink Element={Link} to={companyUrl} kind={LinkKind.Secondary} data-qa="vacancy-serp__vacancy-employer">
            <span className={styles.companyInfoText}>{companyNameText}</span>
        </BlokoLink>
    );
};

export default CompanyName;
