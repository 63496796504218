import { PropsWithChildren, FC } from 'react';

import { useChatik } from '@hh.ru/chatik-integration';
import { Button } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import { IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';

import Source from 'src/components/VacancySearchItem/types/Source';
import useMagritte from 'src/hooks/useMagritte';
import useToggleState from 'src/hooks/useToggleState';
import NegotiationTopic from 'src/models/negotiationTopic.types';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherGetApi {
        '/negotiations/get_topic_by_vacancy_id': {
            queryParams: {
                vacancyId: number;
            };
            response: NegotiationTopic;
        };
    }
}

interface ChatikButtonProps {
    vacancyId: number;
    vacancySource: Source;
    stretched?: boolean;
}

const ChatikButton: FC<ChatikButtonProps & PropsWithChildren> = ({ vacancyId, vacancySource, stretched, children }) => {
    const { openChatik } = useChatik();
    const [loading, toggleLoading] = useToggleState(false);
    const isMagritte = useMagritte();

    const onClick = (): void => {
        if (loading) {
            return;
        }

        toggleLoading();

        void fetcher
            .get('/negotiations/get_topic_by_vacancy_id', {
                params: { vacancyId },
            })
            .then((topic: NegotiationTopic) => {
                openChatik({
                    hhtmFromLabel: vacancySource,
                    ...(topic?.chatId && { chatId: topic.chatId }),
                });
            })
            .catch(() => {
                openChatik({ hhtmFromLabel: vacancySource });
            })
            .finally(() => toggleLoading());
    };

    if (isMagritte) {
        return (
            <Button onClick={onClick} mode="secondary" style="accent" loading={loading} stretched={stretched}>
                {children}
            </Button>
        );
    }

    return (
        <BlokoButton
            onClick={onClick}
            kind={ButtonKind.Primary}
            appearance={ButtonAppearance.Flat}
            loading={loading && <Loading initial={IconColor.White} scale={LoadingScale.Small} />}
        >
            {children}
        </BlokoButton>
    );
};

export default ChatikButton;
