import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Action, BottomSheet, Button as MagritteButton, Drop, NavigationBar, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Down, { DownPlacement } from 'bloko/blocks/drop/Down';
import Loading, { LoadingColor, LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import axios from 'HHC/Axios';
import fetchResponseType from 'src/api/contacts/fetchResponseType';
import { useNotification } from 'src/components/Notifications/Provider';
import RegistrationAction from 'src/components/VacancyActions/RegistrationAction';
import { VacancyResponsePopupResponse } from 'src/components/VacancyResponseLink';
import VacancySearchItemContacts from 'src/components/VacancySearchItem/Contacts';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import useRegisterInteraction from 'src/hooks/vacancies/useRegisterInteraction';
import { UserType } from 'src/models/userType';
import { ContactInfo, VacancyAddress } from 'src/models/vacancyView.types';
import UserLabel from 'src/utils/constants/userLabels';

import vacancySearchItemContactsError from 'src/components/VacancySearchItem/ShowContactsButton/VacancySearchItemContactsError';

type ShowContactsButtonProps = {
    showContact: boolean;
    vacancyId: number;
    address?: VacancyAddress;
    employerId: number;
    chatWritePossibility: ChatWritePossibility;
    onClick?: () => void;
    handleContactsClickRef?: MutableRefObject<{ handleClick?: () => void }>;
    stretched?: boolean;
    magritteButtonSize?: 'medium' | 'small';
    employerManagerName?: string;
};

const TrlKeys = {
    contacts: 'vacancy.search.contacts',
    showContacts: 'vacancy.search.contacts.show',
    seeContacts: 'vacancy.search.contacts.see',
    contact: 'vacancy.of.the.day.show.contact',
    contactB: 'vacancy.search.contact.b',
};

const ShowContactsButton: TranslatedComponent<ShowContactsButtonProps> = ({
    showContact,
    vacancyId,
    address,
    employerId,
    chatWritePossibility,
    onClick,
    handleContactsClickRef,
    stretched,
    employerManagerName,
    magritteButtonSize = 'medium',
    trls,
}) => {
    const isMagritte = useMagritte();
    const [isContactsVisible, setIsContactsVisible] = useState(false);
    const [asyncContacts, setAsyncContacts] = useState<ContactInfo>();
    const [chatButtonInfo, setChatButtonInfo] = useState<VacancyResponsePopupResponse | null>(null);
    const [loadContacts, setLoadContacts] = useState(false);
    const searchLoading = useSelector((state) => state.searchLoading);
    const userType = useSelector((state) => state.userType);
    const userLabel = useSelector((state) => state.userLabelsForVacancies)[vacancyId];
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const { addNotification } = useNotification();
    const contactsButtonRef = useRef(null);
    const isAnonymous = userType === UserType.Anonymous;
    const isApplicant = userType === UserType.Applicant;

    const isQuestionExpB = useExperiment('applicant_search_question_b', isApplicant && isMagritte);
    const isQuestionExpC = useExperiment('applicant_search_question_c', isApplicant && isMagritte);
    const isQuestionExp = isQuestionExpB || isQuestionExpC;

    const registerFetchContactsAttempt = async () => {
        await axios.get(`/shards/vacancy/register_show_contacts_attempt`, {
            params: {
                employerId,
                vacancyId,
            },
        });
    };

    const fetchContacts = async () => {
        if (asyncContacts || isContactsVisible) {
            setIsContactsVisible(!isContactsVisible);
            return;
        }

        setLoadContacts(true);

        if (showContact) {
            let contactsJson: null | {
                data: ContactInfo;
            };
            try {
                contactsJson = await axios.get(`/vacancy/${vacancyId}/contacts`, {
                    params: {
                        employerId,
                    },
                });
            } catch (error) {
                addNotification(vacancySearchItemContactsError);
                setLoadContacts(false);
                return;
            }

            setAsyncContacts(contactsJson?.data);
        } else {
            setAsyncContacts({ fio: employerManagerName as string });
        }

        const chatButtonInfo = await fetchResponseType(vacancyId, isQuestionExp);
        setChatButtonInfo(chatButtonInfo);

        setIsContactsVisible(true);
        setLoadContacts(false);
    };

    const registerInteraction = useRegisterInteraction(vacancyId);

    const handleClick = () => {
        onClick?.();
        Analytics.sendEvent(userType, 'vacancy-view-contacts', 'open');
        void registerFetchContactsAttempt();
        if (isAnonymous) {
            setIsContactsVisible(true);
            void registerInteraction();
        } else {
            void fetchContacts();
        }
    };

    const hideContacts = useCallback(() => {
        setIsContactsVisible(false);
    }, []);

    if (handleContactsClickRef?.current) {
        handleContactsClickRef.current.handleClick = handleClick;
    }

    const initialRender = useRef(true);
    // for ajax load vacancies
    useEffect(() => {
        if (searchLoading) {
            initialRender.current = true;
        }
    }, [searchLoading]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            void fetchResponseType(vacancyId, isQuestionExp).then((chatInfo) => setChatButtonInfo(chatInfo));
        }
    }, [vacancyId, userLabel, isQuestionExp]);

    const showCloseButton = !asyncContacts?.callTrackingEnabled;

    const renderDownContent = () => {
        if (isAnonymous) {
            return <RegistrationAction vacancyId={vacancyId} isMagritte={isMagritte} />;
        }

        return (
            <VacancySearchItemContacts
                employerId={employerId}
                chatWritePossibility={chatWritePossibility}
                vacancyId={vacancyId}
                callTrackingEnabled={asyncContacts?.callTrackingEnabled}
                contactInfo={asyncContacts}
                address={address}
                onClose={hideContacts}
                chatButtonInfo={chatButtonInfo}
                isMagritteExp={isMagritte}
            />
        );
    };

    const userLabels = useSelector((state) => state.userLabelsForVacancies)[vacancyId];
    const isResponded = userLabels?.includes(UserLabel.Response);
    const isInvited = userLabels?.includes(UserLabel.Invite);
    const isDiscarded = userLabels?.includes(UserLabel.Discard);
    const showWorkflowStatus = isDiscarded || isInvited || isResponded;

    if (!showContact && !isQuestionExp) {
        return null;
    }

    if (isMagritte) {
        return (
            <div>
                <MagritteButton
                    ref={contactsButtonRef}
                    mode="secondary"
                    size={magritteButtonSize}
                    style="accent"
                    data-qa="vacancy-serp__vacancy_contacts"
                    loading={loadContacts}
                    onClick={handleClick}
                    stretched={stretched}
                >
                    {isQuestionExpB ? trls[TrlKeys.contactB] : trls[TrlKeys.contact]}
                </MagritteButton>
                <Drop
                    activatorRef={contactsButtonRef}
                    placement="bottom-right"
                    visible={isContactsVisible}
                    onClose={hideContacts}
                    maxWidth={370}
                >
                    {renderDownContent()}
                </Drop>
                <BottomSheet
                    header={
                        <NavigationBar
                            title={trls[TrlKeys.contact]}
                            right={<Action icon={CrossOutlinedSize24} onClick={hideContacts} />}
                        />
                    }
                    visible={isContactsVisible}
                    onClose={hideContacts}
                >
                    {renderDownContent()}
                    <VSpacing default={16} />
                </BottomSheet>
            </div>
        );
    }

    return (
        <Down
            show={isContactsVisible}
            showCloseButton={showCloseButton}
            onClose={hideContacts}
            render={renderDownContent}
            placement={DownPlacement.BottomStart}
        >
            <div>
                <div className={wideCardContainerClassName}>
                    {showWorkflowStatus ? (
                        <Button
                            onClick={handleClick}
                            data-qa="vacancy-serp__vacancy_contacts"
                            kind={ButtonKind.Primary}
                            appearance={ButtonAppearance.Flat}
                            loading={
                                loadContacts && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                            }
                        >
                            {trls[TrlKeys.contacts]}
                        </Button>
                    ) : (
                        <Button
                            kind={ButtonKind.Primary}
                            appearance={ButtonAppearance.Outlined}
                            data-qa="vacancy-serp__vacancy_contacts"
                            onClick={handleClick}
                            loading={
                                loadContacts && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                            }
                            collapsible
                        >
                            <span>{trls[TrlKeys.seeContacts]}</span>
                        </Button>
                    )}
                </div>
                <div className={narrowCardContainerClassName}>
                    {!showWorkflowStatus && (
                        <Button
                            kind={ButtonKind.Primary}
                            appearance={ButtonAppearance.Outlined}
                            data-qa="vacancy-serp__vacancy_contacts"
                            onClick={handleClick}
                            loading={
                                loadContacts && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                            }
                            collapsible
                        >
                            <span>{trls[TrlKeys.contacts]}</span>
                        </Button>
                    )}
                </div>
            </div>
        </Down>
    );
};

export default translation(ShowContactsButton);
