import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlacklistButton from 'src/components/VacancySearchItem/BlacklistButton';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './styles.less';

type ActionsProps = {
    vacancyId: number;
    employerId: number;
    isAnonymousVacancy: boolean;
};

const Actions: TranslatedComponent<ActionsProps> = ({ vacancyId, employerId, isAnonymousVacancy }) => {
    const userType = useSelector((state) => state.userType);
    const { wideCardContainerClassName } = useCardContainerClassNames();
    const isMagritte = useMagritte();

    if (userType !== UserType.Applicant || isMagritte) {
        return null;
    }

    return (
        <div className={classnames(styles.rightSideContainer, wideCardContainerClassName)}>
            <BlacklistButton vacancyId={vacancyId} employerId={employerId} isAnonymousVacancy={isAnonymousVacancy} />
        </div>
    );
};

export default translation(Actions);
